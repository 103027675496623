import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'antd/dist/antd.variable.min.css';
import ConfigProvider from '../../styles/global.theme';
import { Authenticator } from '../../context';
import Routes from '../../pages';
import APPLICATION_ROUTES from '../../utils/routes/appRoutes';
import store from '../../store';
import WebsocketComponent from '../websocket';

function App() {
  return (
    <StoreProvider store={store}>
      <ConfigProvider>
        <Authenticator.AuthProvider>
          <BrowserRouter>
            <Routes routes={APPLICATION_ROUTES} isNotFound />
          </BrowserRouter>
          <WebsocketComponent />
        </Authenticator.AuthProvider>
      </ConfigProvider>
    </StoreProvider >
  );
}

export default App;
