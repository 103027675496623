export const initialState = {
  content: [],
  totalElements: 0,
  pagination: {
    number: 0,
    size: 20,
  },
  sort: ['id,asc'],
  loading: false,
};

export default {
  setUserPagination: (state, action) => {
    state.pagination = { ...action.payload };
  },

  setUserSort: (state, action) => {
    state.sort = [action.payload];
  },

  clearUserData: (state) => {
    state.content = initialState.content;
    state.pagination = initialState.pagination;
    state.totalElements = initialState.totalElements;
    state.sort = initialState.sort;
    state.loading = initialState.loading;
  },
};
