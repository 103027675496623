import { createSlice } from '@reduxjs/toolkit';
import reducers, { initialState } from './nrrdReducer';
import extraReducers from './nrrdExtra';
import { NRRD } from '../../constants';

const nrrdSlice = createSlice({
  name: NRRD,
  initialState,
  reducers,
  extraReducers,
});

export default nrrdSlice;
