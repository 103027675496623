export const SUPER_USER = 'SUPER_USER';
export const NISSIN_USER = 'NISSIN_USER';
export const CUSTOMER = 'CUSTOMER';

export const ROLES = [SUPER_USER, NISSIN_USER, CUSTOMER];

export const ROLE_LABELS = {
  [SUPER_USER]: 'Super user',
  [NISSIN_USER]: 'Nissin user',
  [CUSTOMER]: 'Customer',
};
