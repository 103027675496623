import { getUsers, createUser, updateUser, updateUserPassword, updateUserCredits } from './userExtra';
import userSlice from './userSlice';

const { setUserPagination, setUserSort } = userSlice.actions;

export const setUserPaginationAndFetch = (data) => (dispatch) => {
  dispatch(setUserPagination(data));
  dispatch(getUsers());
};

export const setUserSortAndFetch = (sort = '') => (dispatch) => {
  dispatch(setUserSort(sort));
  dispatch(getUsers());
};

export const createUserAndFetch = (user) => async (dispatch) => {
  await dispatch(createUser({ user }));
  dispatch(getUsers());
};

export const updateUserAndFetch = (user) => async (dispatch) => {
  await dispatch(updateUser({ user }));
  dispatch(getUsers());
};

export const updateUserPasswordAndFetch = (data) => async (dispatch) => {
  await dispatch(updateUserPassword({ data }));
  dispatch(getUsers());
};

export const updateUserCreditsAndFetch = (data) => async (dispatch) => {
  await dispatch(updateUserCredits({ data }));
  dispatch(getUsers());
};
