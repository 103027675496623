import client from '../utils/client';

export const getConfigByParams = (params) => {
  return client.post('/simodont_config', params);
};

export const updateConfigByParams = (data) => {
  return client.put('/simodont_config', data);
};

export const setCurrentWorkflow = (workflow) => {
  return client.post('/log/workflow', {}, {
    params: { workflow },
  });
};

export const logout = () => {
  return client.post('/log/logout');
};

export const getLastLogging = (params) => {
  return client.get('/log', {
    params,
  });
};

export const getLoggingFiles = ({ sort, ...params }) => {
  return client.post('/log/file', {
    ...params,
  }, {
    params: { sort },
  });
};

export const getFile = (id) => {
  return client.get(`/log/file/${id}`, {
    responseType: 'blob',
  });
};
