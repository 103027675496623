import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { Spin } from 'antd';
import { APP_ROUTES } from '../../constants';
import PrivateRoute from './PrivateRouter';

const Routes = ({ routes = [], isNotFound }) => {
  return (
    <Suspense
      fallback={
        <div style={{ width: '100%', height: '100vh' }}>
          <div className="spinner-center">
            <Spin size="large" />
          </div>
        </div>
      }>
      <Switch>
        {routes.map(({ path, page, exact, auth }) =>
          React.createElement(auth ? PrivateRoute : Route, {
            exact,
            path,
            component: page,
            key: path,
          }))}
        {isNotFound && <Redirect from="*" to={APP_ROUTES.NOT_FOUND} />}
      </Switch>
    </Suspense>
  );
};

export default Routes;
