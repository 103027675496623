export const initialState = {
  config: {},
  params: [],
  data: [],
  requestParams: {},
  loading: true,
};

export default {
  setSystemConfig: (state, action) => {
    state.config = action.payload;
  },

  setParams: (state, action) => {
    state.params = action.payload;
  },

  setRequestParams: (state, action) => {
    state.requestParams = action.payload;
  },

  clearSystemConfig: (state) => {
    state.config = { ...initialState.config };
    state.requestParams = { ...initialState.requestParams };
    state.data = [...initialState.data];
    state.params = [...initialState.params];
    state.loading = initialState.loading;
  },
};
