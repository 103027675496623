export const getLocalStorage = (field) => {
  try {
    const serializedState = localStorage.getItem(field);

    if (!serializedState) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch {
    return undefined;
  }
};

export const setLocalStorage = (field, state) => {
  try {
    const serializedState = JSON.stringify(state);

    localStorage.setItem(field, serializedState);
    return true;
  } catch {
    return false;
  }
};

export const removeLocalStorage = (field) => {
  try {
    localStorage.removeItem(field);
    return true;
  } catch {
    return false;
  }
};
