import jwtDecode from 'jwt-decode';
import client from '../client';
import { setLocalStorage } from './storage';

export const getBearerToken = (token) => `Bearer ${token}`;

export const loginSuccess = (data) => {
  const { accessToken, refreshToken } = data;

  client.setAuthToken(getBearerToken(accessToken));
  setLocalStorage('token', accessToken);
  setLocalStorage('refresh_token', refreshToken);
};

export const parseUserData = (data) => {
  const { sub, roles } = jwtDecode(data.accessToken);
  const responseUser = { sub, roles };

  return responseUser;
};
