export const TOOTH_TYPE_BY_TIME = {
  PERMANENT: 'Permanent',
  DECIDUOUS: 'Deciduous',
};

export const ARR_TOOTH_TYPE = [
  {
    label: TOOTH_TYPE_BY_TIME.PERMANENT,
    value: TOOTH_TYPE_BY_TIME.PERMANENT,
  },
  {
    label: TOOTH_TYPE_BY_TIME.DECIDUOUS,
    value: TOOTH_TYPE_BY_TIME.DECIDUOUS,
  },
];

export const TOOTH_TYPE_BY_PLACE = {
  INCISOR: 'Incisor',
  CANINE: 'Canine',
  PREMOLAR: 'Premolar',
  MOLAR: 'Molar',
};

export const DRILLABLE = 'DRILLABLE';
export const NON_DRILLABLE = 'NON_DRILLABLE';

export const COLOR_BY_TYPE = {
  [TOOTH_TYPE_BY_PLACE.INCISOR]: '#ccd300',
  [TOOTH_TYPE_BY_PLACE.CANINE]: '#00ff00',
  [TOOTH_TYPE_BY_PLACE.PREMOLAR]: '#ff00fa',
  [TOOTH_TYPE_BY_PLACE.MOLAR]: '#024993',
  [NON_DRILLABLE]: '#bababa',
};

export const POSITIONS = {
  UPPER_RIGHT: 'Upper right',
  LOWER_RIGHT: 'Lower right',
  UPPER_LEFT: 'Upper left',
  LOWER_LEFT: 'Lower left',
};

export const PERMANENT = {
  [POSITIONS.UPPER_RIGHT]: [
    { title: 18, type: TOOTH_TYPE_BY_PLACE.MOLAR },
    { title: 17, type: TOOTH_TYPE_BY_PLACE.MOLAR },
    { title: 16, type: TOOTH_TYPE_BY_PLACE.MOLAR },
    { title: 15, type: TOOTH_TYPE_BY_PLACE.PREMOLAR },
    { title: 14, type: TOOTH_TYPE_BY_PLACE.PREMOLAR },
    { title: 13, type: TOOTH_TYPE_BY_PLACE.CANINE },
    { title: 12, type: TOOTH_TYPE_BY_PLACE.INCISOR },
    { title: 11, type: TOOTH_TYPE_BY_PLACE.INCISOR },
  ],
  [POSITIONS.UPPER_LEFT]: [
    { title: 21, type: TOOTH_TYPE_BY_PLACE.INCISOR },
    { title: 22, type: TOOTH_TYPE_BY_PLACE.INCISOR },
    { title: 23, type: TOOTH_TYPE_BY_PLACE.CANINE },
    { title: 24, type: TOOTH_TYPE_BY_PLACE.PREMOLAR },
    { title: 25, type: TOOTH_TYPE_BY_PLACE.PREMOLAR },
    { title: 26, type: TOOTH_TYPE_BY_PLACE.MOLAR },
    { title: 27, type: TOOTH_TYPE_BY_PLACE.MOLAR },
    { title: 28, type: TOOTH_TYPE_BY_PLACE.MOLAR },
  ],
  [POSITIONS.LOWER_RIGHT]: [
    { title: 48, type: TOOTH_TYPE_BY_PLACE.MOLAR },
    { title: 47, type: TOOTH_TYPE_BY_PLACE.MOLAR },
    { title: 46, type: TOOTH_TYPE_BY_PLACE.MOLAR },
    { title: 45, type: TOOTH_TYPE_BY_PLACE.PREMOLAR },
    { title: 44, type: TOOTH_TYPE_BY_PLACE.PREMOLAR },
    { title: 43, type: TOOTH_TYPE_BY_PLACE.CANINE },
    { title: 42, type: TOOTH_TYPE_BY_PLACE.INCISOR },
    { title: 41, type: TOOTH_TYPE_BY_PLACE.INCISOR },
  ],
  [POSITIONS.LOWER_LEFT]: [
    { title: 31, type: TOOTH_TYPE_BY_PLACE.INCISOR },
    { title: 32, type: TOOTH_TYPE_BY_PLACE.INCISOR },
    { title: 33, type: TOOTH_TYPE_BY_PLACE.CANINE },
    { title: 34, type: TOOTH_TYPE_BY_PLACE.PREMOLAR },
    { title: 35, type: TOOTH_TYPE_BY_PLACE.PREMOLAR },
    { title: 36, type: TOOTH_TYPE_BY_PLACE.MOLAR },
    { title: 37, type: TOOTH_TYPE_BY_PLACE.MOLAR },
    { title: 38, type: TOOTH_TYPE_BY_PLACE.MOLAR },
  ],
};

export const DECIDUOUS = {
  [POSITIONS.UPPER_RIGHT]: [
    { title: 55, type: TOOTH_TYPE_BY_PLACE.MOLAR },
    { title: 54, type: TOOTH_TYPE_BY_PLACE.MOLAR },
    { title: 53, type: TOOTH_TYPE_BY_PLACE.CANINE },
    { title: 52, type: TOOTH_TYPE_BY_PLACE.INCISOR },
    { title: 51, type: TOOTH_TYPE_BY_PLACE.INCISOR },
  ],
  [POSITIONS.UPPER_LEFT]: [
    { title: 61, type: TOOTH_TYPE_BY_PLACE.INCISOR },
    { title: 62, type: TOOTH_TYPE_BY_PLACE.INCISOR },
    { title: 63, type: TOOTH_TYPE_BY_PLACE.CANINE },
    { title: 64, type: TOOTH_TYPE_BY_PLACE.MOLAR },
    { title: 65, type: TOOTH_TYPE_BY_PLACE.MOLAR },
  ],
  [POSITIONS.LOWER_RIGHT]: [
    { title: 85, type: TOOTH_TYPE_BY_PLACE.MOLAR },
    { title: 84, type: TOOTH_TYPE_BY_PLACE.MOLAR },
    { title: 83, type: TOOTH_TYPE_BY_PLACE.CANINE },
    { title: 82, type: TOOTH_TYPE_BY_PLACE.INCISOR },
    { title: 81, type: TOOTH_TYPE_BY_PLACE.INCISOR },
  ],
  [POSITIONS.LOWER_LEFT]: [
    { title: 71, type: TOOTH_TYPE_BY_PLACE.INCISOR },
    { title: 72, type: TOOTH_TYPE_BY_PLACE.INCISOR },
    { title: 73, type: TOOTH_TYPE_BY_PLACE.CANINE },
    { title: 77, type: TOOTH_TYPE_BY_PLACE.MOLAR },
    { title: 78, type: TOOTH_TYPE_BY_PLACE.MOLAR },
  ],
};

export const CARIOLOGY = 'Cariology';
export const CROWN = 'Crown';
export const DENTAL_ANATOMY = 'Dental Anatomy';
export const ENDO_ACCESS_PREP = 'Endo Access Prep';
export const SOUND = 'Sound';
export const IMPLANTOLOGY = 'Implantology';


export const PROCEDURES = [CARIOLOGY, CROWN, DENTAL_ANATOMY, ENDO_ACCESS_PREP, SOUND, IMPLANTOLOGY];
