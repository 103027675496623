/* eslint-disable no-promise-executor-return */
import client from '../utils/client';
import { removeLocalStorage, getLocalStorage } from '../utils/helpers/storage';
import { loginSuccess, parseUserData } from '../utils/helpers/auth';

// fetch
export const fetchLogin = (userData) => {
  return client.post('/auth', userData);
};

export const fetchRelogin = (refreshToken) => {
  return client.post('/refresh', { refreshToken });
};

// action
export const login = async (authData) => {
  const { data } = await fetchLogin(authData);

  loginSuccess(data);
  return { data: parseUserData(data) };
};

export const relogin = async () => {
  const refreshToken = getLocalStorage('refresh_token');

  if (refreshToken) {
    const { data } = await fetchRelogin(refreshToken);

    loginSuccess(data);
    return { data: parseUserData(data) };
  }
  return { data: null };
};

export const logout = () => {
  client.setAuthToken('');
  removeLocalStorage('token');
  removeLocalStorage('refresh_token');
};
