import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { STL, LOADING_CONSTANT, OBJ } from '../../constants';
import { fileService } from '../../services';

// extra actions
export const saveModelToStore = createAsyncThunk('file/saveModelToStore', async (_, thunkAPI) => {
  const { file } = thunkAPI.getState().model;
  const { data } = await fileService.downloadFile({ id: file.id, ext: STL });

  return { data };
});

export const prepareModelAction = createAsyncThunk('file/prepareModelAction', async (_, thunkAPI) => {
  const { file } = thunkAPI.getState().nrrd;

  await fileService.prepareModel({ id: file.id });
});

export const saveOBJToStore = createAsyncThunk('file/saveOBJToStore', async (_, thunkAPI) => {
  const { obj } = thunkAPI.getState().model;
  const { data } = await fileService.downloadFile({ id: obj.id, ext: OBJ });

  return { data };
});

export const uploadScreenshotAction = createAsyncThunk('file/uploadScreenshotAction', async ({ data }, thunkAPI) => {
  const { file } = thunkAPI.getState().nrrd;
  const formData = new FormData();

  formData.append('file', data);

  await fileService.uploadFileData({ fileMetadataId: file.id, formData });

  return { data };
});

export const cancelModelConversion = createAsyncThunk('file/cancelModelConversion', async (_, thunkAPI) => {
  const { file } = thunkAPI.getState().nrrd;

  await fileService.cancelModelConversion({ id: file.id });
});


// extra reducer
export default {
  [saveModelToStore.pending]: (state) => {
    state.loading[LOADING_CONSTANT.MODEL_EXTRACTION] = false;
    state.loading[LOADING_CONSTANT.MODEL_DOWNLOAD] = true;

    notification.success({
      key: LOADING_CONSTANT.MODEL_EXTRACTION,
      duration: 3,
      message: 'Model extraction (100%)',
      description: 'Model have been extracted! Result file is uploading',
    });
  },
  [saveModelToStore.fulfilled]: (state, action) => {
    const { data } = action.payload;

    state.file = { ...state.file, data };
    state.loading[LOADING_CONSTANT.MODEL_DOWNLOAD] = false;
  },
  [saveModelToStore.rejected]: (state) => {
    state.loading[LOADING_CONSTANT.MODEL_DOWNLOAD] = false;

    notification.error({
      key: LOADING_CONSTANT.MODEL_EXTRACTION,
      duration: 3,
      message: 'Model downloading',
      description: 'Error when downloading file',
    });
  },

  [prepareModelAction.pending]: (state) => {
    state.loading[LOADING_CONSTANT.MODEL_EXTRACTION] = true;
    state.file = { id: 0, data: null };
    state.obj = { id: 0, data: null };

    notification.success({
      key: LOADING_CONSTANT.MODEL_EXTRACTION,
      duration: 0,
      message: 'Model extraction (0%)',
      description: 'Model is preparing! Please wait for the end',
    });
  },
  [prepareModelAction.fulfilled]: (state) => {
    state.loading[LOADING_CONSTANT.MODEL_EXTRACTION] = true;
  },
  [prepareModelAction.rejected]: (state) => {
    state.loading[LOADING_CONSTANT.MODEL_EXTRACTION] = false;

    notification.error({
      key: LOADING_CONSTANT.MODEL_EXTRACTION,
      duration: 3,
      message: 'Model extraction',
      description: 'Error when preparing model',
    });
  },

  [saveOBJToStore.pending]: (state) => {
    state.loading[LOADING_CONSTANT.MODEL_EXTRACTION] = false;
    state.loading[LOADING_CONSTANT.MODEL_DOWNLOAD] = true;

    notification.success({
      key: LOADING_CONSTANT.MODEL_EXTRACTION,
      duration: 3,
      message: 'Model extraction (100%)',
      description: 'Model have been extracted! Result file is uploading',
    });
  },
  [saveOBJToStore.fulfilled]: (state, action) => {
    const { data } = action.payload;

    state.obj = { ...state.obj, data };
    state.loading[LOADING_CONSTANT.MODEL_DOWNLOAD] = false;
  },
  [saveOBJToStore.rejected]: (state) => {
    state.loading[LOADING_CONSTANT.MODEL_DOWNLOAD] = false;

    notification.error({
      key: LOADING_CONSTANT.MODEL_EXTRACTION,
      duration: 3,
      message: 'Model downloading',
      description: 'Error when downloading file',
    });
  },

  [uploadScreenshotAction.fulfilled]: (state, action) => {
    notification.success({
      message: 'Screenshot upload',
      description: 'File has been uploaded!',
    });

    state.screenshot = action.payload.data;
  },
  [uploadScreenshotAction.rejected]: () => {
    notification.error({
      message: 'Screenshot upload',
      description: 'Error when upload file',
    });
  },
};
