import modelSlice from './modelSlice';

export {
  saveModelToStore,
  prepareModelAction,
  uploadScreenshotAction,
  saveOBJToStore,
  cancelModelConversion,
} from './modelExtra';

export * from './modelActions';
export * from './modelHook';

export const {
  setIdModel,
  setModelFile,
  startModelLoading,
  stopModelLoading,
  setModelBackgroundColor,
  setIdOBJ,
  setOBJFile,
  setTeethNumber,
  clearModelData,
} = modelSlice.actions;

export default modelSlice.reducer;
