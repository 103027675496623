import modelSlice from './simodontModelSlice';

export {
  saveSimodontModelToStore,
  prepareSimodontModelAction,
  uploadTargetInToothDataAction,
  cancelSimodontModelConversion,
} from './simodontModelExtra';

export * from './simodontModelActions';
export * from './simodontModelHook';

export const {
  setIdSimodontModel,
  setSimodontModelFile,
  setSimodontModelLoading,
  stopSimodontModelLoading,
  setSimodontModelInfo,
  changeFileByName,
  changePadding,
  addTeethInfo,
  setTeethInfo,
  removeTeethInfo,
  clearTeethInfo,
  clearSimodontFormData,
  clearSimodontModelData,
  clearSimodontModelFile,
} = modelSlice.actions;

export default modelSlice.reducer;
