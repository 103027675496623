import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  clearSystemConfig,
  setParamsAndFetchConfig,
  setRequestParamsAndFetch,
  updateParamsAndFetchConfig,
  useSystemConfigState,
} from '../store';
import { configService } from '../services';

const useSystemConfig = () => {
  const dispatch = useDispatch();
  const { config, requestParams, data, loading } = useSystemConfigState();

  const setParams = useCallback((params) => dispatch(setParamsAndFetchConfig(params)), [dispatch]);
  const updateParams = useCallback((params) => dispatch(updateParamsAndFetchConfig(params)), [dispatch]);
  const getLogging = useCallback((params) => dispatch(setRequestParamsAndFetch(params, configService.getLastLogging)), [dispatch]);
  const getFiles = useCallback((params) => dispatch(setRequestParamsAndFetch(params, configService.getLoggingFiles)), [dispatch]);

  const clearData = useCallback(() => dispatch(clearSystemConfig()), [dispatch]);

  const methods = {
    setParams,
    updateParams,
    getLogging,
    getFiles,
    clearData,
  };

  return { config, data, requestParams, loading, methods };
};

export default useSystemConfig;
