export const getApiFunc = (fetchFunc, overrideOptions) => async (requestData, options) => {
  const onStart = () => {
    overrideOptions?.onStart?.();
    options?.onStart?.();
  };

  const onSuccess = () => {
    overrideOptions?.onSuccess?.();
    options?.onSuccess?.();
  };

  const onError = (e) => {
    overrideOptions?.onError?.(e);
    options?.onError?.(e);
  };

  const onFinished = () => {
    overrideOptions?.onFinished?.();
    options?.onFinished?.();
  };

  try {
    onStart?.();
    const { data } = await fetchFunc(requestData);

    onSuccess?.();
    return { data };
  } catch (e) {
    onError?.(e);
    return { data: null };
  } finally {
    onFinished?.();
  }
};
