import { SINGLE_TOOTH } from '../constants';
import client from '../utils/client';

export const uploadFiles = ({ formData }) => {
  const isSingleTooth = localStorage.getItem('workflow') === SINGLE_TOOTH;

  return client.post(`/${isSingleTooth ? 'single' : 'full'}/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const downloadFile = ({ id, ext }) => {
  return client.get(`/fileMetadata/download/${id}`, {
    params: { ext },
    responseType: 'blob',
  });
};

export const prepareModel = ({ id }) => {
  const isSingleTooth = localStorage.getItem('workflow') === SINGLE_TOOTH;

  return client.get(`/fileMetadata/${isSingleTooth ? 'single' : 'full'}/model/${id}`);
};

export const uploadFileData = ({ formData, fileMetadataId }) => {
  const isSingleTooth = localStorage.getItem('workflow') === SINGLE_TOOTH;

  return client.post(`/file_data/${isSingleTooth ? 'single' : 'full'}/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: {
      fileMetadataId,
    },
  });
};

export const prepareSimodontModel = ({
  id,
  fromNrrd,
  backgroundColor: { r, g, b },
  name,
  description,
  procedure,
  teethInfo,
}) => {
  const isSingleTooth = localStorage.getItem('workflow') === SINGLE_TOOTH;

  return client.post(`/simodont_model/${isSingleTooth ? 'single' : 'full'}/generate/${id}`, {
    isSingleTooth: localStorage.getItem('workflow') === SINGLE_TOOTH,
    fromNrrd,
    backgroundColor: {
      'red': r,
      'green': g,
      'blue': b,
    },
    name,
    description,
    procedure,
    teethInfo,
  });
};

export const prepareTargetInToothModel = ({ formData }) => {
  return client.post('/target/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const cancelDicomConversion = ({ id }) => {
  const isSingleTooth = localStorage.getItem('workflow') === SINGLE_TOOTH;

  return client.get(`/${isSingleTooth ? 'single' : 'full'}/cancel/${id}`);
};

export const cancelNrrdConversion = ({ id }) => {
  const isSingleTooth = localStorage.getItem('workflow') === SINGLE_TOOTH;

  return client.get(`/file_data/${isSingleTooth ? 'single' : 'full'}/cancel/${id}`);
};

export const cancelModelConversion = ({ id }) => {
  return client.get(`/fileMetadata/cancel/${id}`);
};

export const cancelSimodontModelConversion = ({ id }) => {
  return client.get(`/simodont_model/cancel/${id}`);
};
