import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { userService } from '../../services';

// extra actions
export const getUsers = createAsyncThunk('user/getUsers', async (_, thunkAPI) => {
  const { pagination, sort } = thunkAPI.getState().user;
  const { data } = await userService.fetchAllUsers({ pagination, sort });

  return data;
});

export const createUser = createAsyncThunk('user/createUsers', async ({ user }) => {
  await userService.createUser(user);
});

export const updateUser = createAsyncThunk('user/updateUser', async ({ user }) => {
  await userService.updateUser([user]);
});

export const updateUserPassword = createAsyncThunk('user/updateUserPassword', async ({ data }) => {
  await userService.changePasswordForUser(data);
});

export const updateUserCredits = createAsyncThunk('user/updateUserPassword', async ({ data }) => {
  await userService.updateUserCredits(data);
});

// extra reducer
export default {
  [getUsers.pending]: (state) => {
    state.loading = true;
  },
  [getUsers.fulfilled]: (state, action) => {
    const { content, totalElements } = { ...action.payload };

    state.content = content;
    state.totalElements = totalElements;
    state.loading = false;
  },
  [getUsers.rejected]: (state) => {
    state.loading = false;
  },

  [createUser.pending]: (state) => {
    state.loading = true;
  },
  [createUser.fulfilled]: (state) => {
    state.loading = false;

    notification.success({
      message: 'User creation',
      description: 'User was created successfully',
    });
  },
  [createUser.rejected]: (state) => {
    state.loading = false;

    notification.error({
      message: 'User creation',
      description: 'Something was wrong',
    });
  },

  [updateUser.pending]: (state) => {
    state.loading = true;
  },
  [updateUser.fulfilled]: (state) => {
    state.loading = false;

    notification.success({
      message: 'User update',
      description: 'User was updated successfully',
    });
  },
  [updateUser.rejected]: (state) => {
    state.loading = false;

    notification.error({
      message: 'User update',
      description: 'Something was wrong',
    });
  },

  [updateUserPassword.pending]: (state) => {
    state.loading = true;
  },
  [updateUserPassword.fulfilled]: (state) => {
    state.loading = false;

    notification.success({
      message: 'User password update',
      description: 'User password was updated successfully',
    });
  },
  [updateUserPassword.rejected]: (state) => {
    state.loading = false;

    notification.error({
      message: 'User password update',
      description: 'Something was wrong',
    });
  },

  [updateUserCredits.pending]: (state) => {
    state.loading = true;
  },
  [updateUserCredits.fulfilled]: (state) => {
    state.loading = false;

    notification.success({
      message: 'User credits update',
      description: 'User credits was updated successfully',
    });
  },
  [updateUserCredits.rejected]: (state) => {
    state.loading = false;

    notification.error({
      message: 'User credits update',
      description: 'Something was wrong',
    });
  },
};
