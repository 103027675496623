import { createSlice } from '@reduxjs/toolkit';
import reducers, { initialState } from './simodontModelReducer';
import extraReducers from './simodontModelExtra';

const modelSlice = createSlice({
  name: 'simodontModel',
  initialState,
  reducers,
  extraReducers,
});

export default modelSlice;
