import { LOADING_CONSTANT } from '../../constants';

export const initialState = {
  file: {
    id: 0,
    data: null,
  },
  obj: {
    id: 0,
    data: null,
  },
  teethNumber: 0,
  backgroundColor: { r: 70, g: 30, b: 20 },
  screenshot: null,
  loading: {
    [LOADING_CONSTANT.MODEL_EXTRACTION]: false,
    [LOADING_CONSTANT.MODEL_DOWNLOAD]: false,
    [LOADING_CONSTANT.MODEL_SETTINGS]: false,
  },
};

export default {
  setIdModel: (state, action) => {
    state.file = { id: action.payload.id, data: null };
  },

  setModelFile: (state, action) => {
    state.file = { id: 0, data: action.payload.file };
  },

  setIdOBJ: (state, action) => {
    state.obj = { id: action.payload.id, data: null };
  },

  setOBJFile: (state, action) => {
    state.obj = { id: 0, data: action.payload.file };
  },

  setModelBackgroundColor: (state, action) => {
    state.backgroundColor = action.payload.color;
  },

  setTeethNumber: (state, action) => {
    state.teethNumber = action.payload;
  },

  startModelLoading: (state, action) => {
    state.loading[action.payload.process] = true;
  },

  stopModelLoading: (state, action) => {
    state.loading[action.payload.process] = false;
  },

  clearModelData: (state) => {
    state.file = { ...initialState.file };
    state.obj = { ...initialState.obj };
    state.loading = { ...initialState.loading };
    state.backgroundColor = { ...initialState.backgroundColor };
    state.screenshot = initialState.screenshot;
    state.teethNumber = initialState.teethNumber;
  },
};
