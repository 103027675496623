import { createSlice } from '@reduxjs/toolkit';
import reducers, { initialState } from './systemConfigReducer';
import extraReducers from './systemConfigExtra';

const systemConfigSlice = createSlice({
  name: 'systemConfig',
  initialState,
  reducers,
  extraReducers,
});

export default systemConfigSlice;
