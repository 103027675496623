import { createSelector } from '@reduxjs/toolkit';

export const selectUserState = (state) => state.user;

export const selectUserContent = createSelector(selectUserState, (state) => state.content);
export const selectUserPagination = createSelector(selectUserState, (state) => state.pagination);
export const selectUserTotalElements = createSelector(
  selectUserState,
  (state) => state.totalElements,
);
export const selectUserLoading = createSelector(selectUserState, (state) => state.loading);
