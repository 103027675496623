import { useDispatch } from 'react-redux';
import { SINGLE_TOOTH } from '../constants';
import {
  setModelIdAndDownload,
  prepareModelAction,
  useModelState,
  setModelFile,
  stopModelLoading,
  setModelBackgroundColor,
  clearModelData,
  uploadScreenshotAction,
  setOBJIdAndDownload,
  setOBJFile,
  cancelModelConversion,
  startModelLoading,
  setTeethNumber,
} from '../store';

const useModelFile = () => {
  const dispatch = useDispatch();
  const { file, obj, teethNumber, backgroundColor, screenshot, loading } = useModelState();

  const handleSetDownloadFile = (data) => {
    dispatch(setModelIdAndDownload(data));
  };

  const handleSetDownloadOBJ = (data) => {
    dispatch(setOBJIdAndDownload(data));
  };

  const handlePrepareModel = () => {
    dispatch(prepareModelAction());
  };

  const handleSetModel3D = (data) => {
    if (localStorage.getItem('workflow') === SINGLE_TOOTH) {
      dispatch(setModelFile({ file: data }));
    } else {
      dispatch(setOBJFile({ file: data }));
    }
  };

  const handleStartModelLoading = (process) => {
    dispatch(startModelLoading({ process }));
  };

  const handleStopModelLoading = (process) => {
    dispatch(stopModelLoading({ process }));
  };

  const handleChangeModelBackground = (color) => {
    dispatch(setModelBackgroundColor({ color }));
  };

  const handleUploadScreenshot = (data) => {
    dispatch(uploadScreenshotAction({ data }));
  };

  const handleSetTeethNumber = (num) => {
    dispatch(setTeethNumber(num));
  };

  const handleCancelConversion = () => {
    dispatch(cancelModelConversion());
  };

  const handleClearAllData = () => {
    dispatch(clearModelData());
  };

  const methods = {
    handleSetDownloadFile,
    handleSetDownloadOBJ,
    handlePrepareModel,
    handleSetModel3D,
    handleStartModelLoading,
    handleStopModelLoading,
    handleChangeModelBackground,
    handleUploadScreenshot,
    handleSetTeethNumber,
    handleCancelConversion,
    handleClearAllData,
  };

  return { file, obj, backgroundColor, teethNumber, screenshot, loading, methods };
};

export default useModelFile;
