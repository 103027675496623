import { ConfigProvider } from 'antd';


ConfigProvider.config({
  theme: {
    primaryColor: '#00afb7',
  },
});

export default ConfigProvider;
