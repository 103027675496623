import { lazy } from 'react';
import Routes from './Routes';

export const Login = lazy(() => import('./Auth/Login'));
export const Workflow = lazy(() => import('./Workflow'));
export const Model = lazy(() => import('./Model'));
export const TargetInTooth = lazy(() => import('./TargetInTooth'));
export const UserManagement = lazy(() => import('./UserManagement'));
export const SystemConfig = lazy(() => import('./SystemConfig'));
export const NotFound = lazy(() => import('./NotFound'));

export default Routes;
