import { CUSTOMER, ROLES } from '../../constants';

export const sortFileArr = (arr, sort) => {
  const newArr = [...arr];

  const resultArr = newArr.sort((a, b) => {
    if (sort.name === 'filename') {
      if (sort.asc) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      }

      return -a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    } else if (sort.name === 'type') {
      const arrA = a.name.split('.');
      const arrB = b.name.split('.');
      const typeA = arrA[arrA.length - 1];
      const typeB = arrB[arrB.length - 1];

      if (sort.asc) {
        return typeA >= typeB ? 1 : -1;
      }

      return typeA <= typeB ? 1 : -1;
    }
  });

  return resultArr;
};

export const devideRoleAndPermissions = (userRoles = []) => {
  return userRoles.reduce((acc, v) => {
    const role = typeof v === 'string' ? v : v.name;

    if (ROLES.includes(role)) {
      acc.role = role;
    } else {
      acc.permissions.push(role);
    }

    return acc;
  }, {
    role: '',
    permissions: [],
  });
};

export const accessIsAllowed = (item, roles = []) => {
  const { role, permissions } = devideRoleAndPermissions(roles);

  if (item.roles.length) {
    if (role !== CUSTOMER) {
      return roles.some((v) => item.roles.includes(v));
    }

    return permissions.includes(item.workflow);
  }

  return true;
};

export const generatePassword = () => {
  let generatedPassword = '';
  const charsString = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz';

  for (let i = 0; i < 10; i += 1) {
    generatedPassword += charsString.charAt(Math.floor(Math.random() * charsString.length));
  }

  const randomPos = Math.round(Math.random() * 10);
  const randomNum = Math.round(Math.random() * 9);
  const password = generatedPassword.substring(0, randomPos) +
    randomNum +
    generatedPassword.substring(randomPos, generatedPassword.length);

  return password;
};

export function getCanvasBlob(canvas, extention) {
  return new Promise((res) => {
    canvas.toBlob((blob) => {
      res(blob);
    }, extention);
  });
}
