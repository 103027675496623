import client from '../../utils/client';
import { authService } from '../../services';
import { getLocalStorage } from '../../utils/helpers/storage';
import { getBearerToken } from '../../utils/helpers/auth';
import { CODE_ERRORS } from '../../constants';
import { notification } from 'antd';

const authInterceptor = ({ onLogout }) => {
  const onResponseSuccess = (response) => response;

  const onResponseFail = async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === CODE_ERRORS.UNAUTHORIZED) {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        await authService.relogin();
        originalRequest.headers.Authorization = getBearerToken(getLocalStorage('token'));
        return client.instance(originalRequest);
      }
      onLogout(false);
    }
    if (error?.response?.status === CODE_ERRORS.FORBIDDEN) {
      notification.error({
        message: 'The session is timed out',
        description: 'Please log in again to continue',
      });
      onLogout(false);
    }
    return Promise.reject(error);
  };

  return [onResponseSuccess, onResponseFail];
};

export default authInterceptor;
