import { FULL_JAW, SINGLE_TOOTH, SYSTEM_CONFIG, TARGET_IN_TOOTH, USER_MANAGEMENT } from './workflows';

const APP_ROUTES = {
  ROOT: '/',

  [FULL_JAW]: '/full_jaw',
  [SINGLE_TOOTH]: '/single_tooth',
  [TARGET_IN_TOOTH]: '/target_in_tooth',
  [USER_MANAGEMENT]: '/user_management',
  [SYSTEM_CONFIG]: '/system_config',

  // Auth
  LOGIN: '/login',

  // Errors
  NOT_FOUND: '/404',
};

export default APP_ROUTES;
