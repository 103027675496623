import { combineReducers } from '@reduxjs/toolkit';
import { ROOT_LOGOUT } from './rootActionsConstants';

// reducers
import model from './model';
import nrrd from './nrrd';
import simodontModel from './simodontModel';
import user from './user';
import systemConfig from './systemConfig';

const combinedReducer = combineReducers({
  model,
  nrrd,
  simodontModel,
  user,
  systemConfig,
});

const rootReducer = (state, action) => {
  console.log(action);
  if (action.type === ROOT_LOGOUT) {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
