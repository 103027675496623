/* eslint-disable max-statements */
/* eslint-disable indent */
import React, { useContext } from 'react';
import SockJsClient from 'react-stomp';
import { notification } from 'antd';
import {
  WEBSOCKET_URL,
  TOPICS,
  NRRD,
  STL,
  ZIP,
  LOADING_CONSTANT,
  OBJ,
} from '../../constants';
import { useModel, useNrrd, useSimodontModel } from '../../hooks';
import { Authenticator } from '../../context';

const WebsocketComponent = () => {
  const { addWebsocketId, user, setIsCleanUp } = useContext(Authenticator.AuthContext);
  const { methods: nrrdMethods } = useNrrd();
  const { methods: modelMethods } = useModel();
  const { methods: simodontModelMethods } = useSimodontModel();
  const handleStopLoading = {
    [LOADING_CONSTANT.DICOM_CONVERSION]: nrrdMethods.handleStopNrrdLoading,
    [LOADING_CONSTANT.NRRD_PREPARING]: nrrdMethods.handleStopNrrdLoading,
    [LOADING_CONSTANT.MODEL_EXTRACTION]: modelMethods.handleStopModelLoading,
    [LOADING_CONSTANT.SIMODONT_MODEL_CONVERSION]: simodontModelMethods.handleStopSimodontModelLoading,
  };

  const handleMessage = ({ messageType, message }) => {
    console.log(messageType, message);
    switch (messageType) {
      case 'AUTH': {
        addWebsocketId(message.userName);

        break;
      }
      case 'CLEANUP_STATUS': {
        setIsCleanUp({
          loading: false,
          process: message.cleanupInProgress,
        });

        break;
      }
      case 'FILE_READY': {
        if (message.extension === NRRD) {
          nrrdMethods.handleSetDownloadFile(message);
        } else if (message.extension === STL) {
          modelMethods.handleSetDownloadFile(message);
        } else if (message.extension === ZIP) {
          simodontModelMethods.handleSetDownloadFile(message);
        } else if (message.extension === OBJ) {
          modelMethods.handleSetDownloadOBJ(message);
        }

        break;
      }
      case 'PROGRESS_NOTICE': {
        if (message.progressValue !== -1) {
          notification.success({
            key: message.processTitle.replace(/ /g, '_').toUpperCase(),
            duration: 0,
            message: `${message.processTitle} (${message.progressValue}%)`,
            description: message.processStage,
          });
        } else {
          const process = message.processTitle.replace(/ /g, '_').toUpperCase();

          handleStopLoading[process](process);
          notification.info({
            key: message.processTitle.replace(/ /g, '_').toUpperCase(),
            duration: 0,
            message: `${message.processTitle}`,
            description: message.processStage,
          });
        }
        break;
      }
      case 'ALARM': {
        const process = message.messageTitle.replace(/ /g, '_').toUpperCase();

        handleStopLoading[process](process);
        notification.error({
          key: process,
          duration: 3,
          message: message.messageTitle,
          description: message.messageContent,
        });
        break;
      }
      default: {
        console.error(messageType, message);
      }
    }
  };

  return (
    <div>
      {user && (
        <SockJsClient
          url={WEBSOCKET_URL}
          topics={TOPICS}
          onMessage={handleMessage}
          subscribeHeaders={
            {
              username: user.sub,
            }
          }
        />
      )}
    </div>
  );
};

export default WebsocketComponent;
