import { notification } from 'antd';
import { LOADING_CONSTANT } from '../../constants';
import { sortFileArr } from '../../utils/helpers/func';

export const initialState = {
  dicomFiles: [],
  file: {
    id: 0,
    batchId: '',
    data: null,
  },
  loading: {
    [LOADING_CONSTANT.DICOM_CONVERSION]: false,
    [LOADING_CONSTANT.NRRD_PREPARING]: false,
    [LOADING_CONSTANT.NRRD_UPLOAD]: false,
    [LOADING_CONSTANT.NRRD_DOWNLOAD]: false,
  },
};

export default {
  addUploadFiles: (state, action) => {
    const addingArray = action.payload.files.filter((v) => {
      const nameArr = v.name.split('.');
      const ext = nameArr[nameArr.length - 1];

      return ext === 'dcm' || ext === 'dicom' || ext === 'dic';
    });

    if (addingArray.length !== action.payload.files.length) {
      notification.info({
        message: 'Choosing file',
        description: 'Some files have unsupported type. They will be ignored',
      });
    }
    const arr = [...state.dicomFiles, ...addingArray];
    const repeat = {};
    const resultArr = [];

    for (const v of arr) {
      if (!repeat[v.name]) {
        repeat[v.name] = true;
        resultArr.push(v);
      }
    }

    state.dicomFiles = sortFileArr(resultArr, { name: 'filename', asc: true });
  },

  removeUploadFiles: (state, action) => {
    const { data } = action.payload;
    const index = state.dicomFiles.indexOf(data);
    const newFileList = [...state.dicomFiles];

    newFileList.splice(index, 1);
    state.dicomFiles = [...newFileList];
  },

  setIdNrrd: (state, action) => {
    state.file = { id: action.payload.id, data: null };
  },

  setNrrdFile: (state, action) => {
    state.file = { id: 0, batchId: '', data: action.payload.file };
  },

  stopNrrdLoading: (state, action) => {
    state.loading[action.payload.process] = false;
  },

  clearDicomFiles: (state) => {
    state.dicomFiles = [];
  },

  clearNrrdData: (state) => {
    state.dicomFiles = [...initialState.dicomFiles];
    state.file = { ...initialState.file };
    state.loading = { ...initialState.loading };
  },
};
