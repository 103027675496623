import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { APP_ROUTES } from '../../constants';
import { AuthContext } from '../../context/Authentication';

const PrivateRoute = ({ component: RouteComponent, exact, path }) => {
  const { user } = useContext(AuthContext);

  if (!user) {
    localStorage.setItem('workflow', '');
    return (
      <Redirect
        to={{
          pathname: APP_ROUTES.LOGIN,
        }}
      />
    );
  }

  return <Route exact={exact} path={path} component={RouteComponent} />;
};

export default PrivateRoute;
