import systemConfigSlice from './systemConfigSlice';

export {
  getConfigByParams,
  updateConfigByParams,
  getDataWithRequestParams,
} from './systemConfigExtra';

export * from './systemConfigActions';
export * from './systemConfigHook';

export const {
  setSystemConfig,
  setParams,
  clearSystemConfig,
  setRequestParams,
} = systemConfigSlice.actions;

export default systemConfigSlice.reducer;
