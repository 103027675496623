const CREDIT_SYSTEM = 'credit';
const LAST_LOGGING = 'last_logging';
const LOGGING_FILES = 'logging_files';

export const CONFIG_PAGES = {
  CREDIT_SYSTEM, LAST_LOGGING, LOGGING_FILES,
};

export const STL_COST = 'STL_TOOTH_COST';
export const NON_DRILLABLE_COST = 'MODEL_NOT_DRILLABLE_TOOTH_COST';
export const DRILLABLE_COST = 'MODEL_DRILLABLE_TOOTH_COST';
