import { notification } from 'antd';
import { LOADING_CONSTANT } from '../../constants';

export const initialState = {
  file: {
    id: 0,
    data: null,
  },
  info: {
    name: '',
    description: '',
    procedure: [],
  },
  teethInfo: [],
  formData: {
    singleLayer: null,
    crown: null,
    root: null,
    idealPrep: null,
    underPrep: null,
    overPrepSub: null,
    overPrepMax: null,
    padding: 1,
  },
  loading: {
    [LOADING_CONSTANT.SIMODONT_MODEL_CONVERSION]: false,
    [LOADING_CONSTANT.SIMODONT_MODEL_DOWNLOAD]: false,
  },
};

export default {
  changeFileByName: (state, action) => {
    const { file, name } = action.payload;

    if (file) {
      const nameArr = file.name.split('.');
      const ext = nameArr[nameArr.length - 1];

      if (ext === 'stl') {
        state.formData[name] = file;
      } else {
        notification.info({
          message: 'Choosing file',
          description: 'This file has unsupported type. It will be ignored',
        });
      }
    } else {
      state.formData[name] = null;
    }
  },

  changePadding: (state, action) => {
    const { value } = action.payload;

    state.formData.padding = value;
  },

  setIdSimodontModel: (state, action) => {
    state.file = { id: action.payload.id, data: null };
  },

  setSimodontModelFile: (state, action) => {
    state.file = { id: 0, data: action.payload.file };
  },

  setSimodontModelLoading: (state, action) => {
    state.loading = action.payload.loading;
  },

  stopSimodontModelLoading: (state, action) => {
    state.loading[action.payload.process] = false;
  },

  setSimodontModelInfo: (state, action) => {
    state.info = { ...action.payload };
  },

  addTeethInfo: (state, action) => {
    state.teethInfo.push(action.payload);
  },

  setTeethInfo: (state, action) => {
    state.teethInfo = [...action.payload.teethInfo];
  },

  removeTeethInfo: (state, action) => {
    const { toothPosition } = action.payload;

    state.teethInfo = state.teethInfo.filter((v) => v.toothPosition !== toothPosition);
  },

  clearTeethInfo: (state) => {
    state.teethInfo = [];
  },

  clearSimodontFormData: (state) => {
    state.formData = { ...initialState.formData };
  },

  clearSimodontModelFile: (state) => {
    state.file = { ...initialState.file };
  },

  clearSimodontModelData: (state) => {
    state.file = { ...initialState.file };
    state.loading = { ...initialState.loading };
    state.formData = { ...initialState.formData };
    state.info = { ...initialState.info };
    state.teethInfo = initialState.teethInfo;
    state.teethType = initialState.teethType;
  },
};
