// nrrd store
const DICOM_CONVERSION = 'DICOM_CONVERSION';
const NRRD_PREPARING = 'NRRD_PREPARING';
const NRRD_UPLOAD = 'NRRD_UPLOAD';
const NRRD_DOWNLOAD = 'NRRD_DOWNLOAD';

// model store
const MODEL_EXTRACTION = 'MODEL_EXTRACTION';
const MODEL_DOWNLOAD = 'MODEL_DOWNLOAD';
const MODEL_SETTINGS = 'MODEL_SETTINGS';

// simodont model store
const SIMODONT_MODEL_CONVERSION = 'SIMODONT_MODEL_CONVERSION';
const SIMODONT_MODEL_DOWNLOAD = 'SIMODONT_MODEL_DOWNLOAD';

export const LOADING_CONSTANT = {
  DICOM_CONVERSION,
  NRRD_PREPARING,
  NRRD_UPLOAD,
  NRRD_DOWNLOAD,

  MODEL_EXTRACTION,
  MODEL_DOWNLOAD,
  MODEL_SETTINGS,

  SIMODONT_MODEL_CONVERSION,
  SIMODONT_MODEL_DOWNLOAD,
};
