import { useDispatch } from 'react-redux';
import {
  useNrrdState,
  addUploadFiles,
  removeUploadFiles,
  stopNrrdLoading,
  clearDicomFiles,
  clearNrrdData,
  uploadDicomAndClearOtherData,
  uploadNrrdAndClearOtherData,
  cancelNrrdConversion,
  setNrrdFile,
  setIdNrrdAndDownload,
} from '../store';

const useNrrdFile = () => {
  const dispatch = useDispatch();
  const { dicomFiles, file, loading } = useNrrdState();

  const handleUploadFiles = (files) => {
    dispatch(addUploadFiles({ files }));
  };

  const handleRemoveUploadFile = (data) => {
    dispatch(removeUploadFiles({ data }));
  };

  const handleConvertFiles = () => {
    dispatch(uploadDicomAndClearOtherData());
  };

  const handleSetDownloadFile = (data) => {
    dispatch(setIdNrrdAndDownload(data));
  };

  const handleSetNrrd = () => {
    dispatch(uploadNrrdAndClearOtherData());
  };

  const handleSetNrrdData = (data) => {
    dispatch(setNrrdFile({ file: data }));
  };

  const handleStopNrrdLoading = (process) => {
    dispatch(stopNrrdLoading({ process }));
  };

  const handleCancelConversion = () => {
    dispatch(cancelNrrdConversion());
  };

  const handleClearDicomFiles = () => {
    dispatch(clearDicomFiles());
  };

  const handleClearAllData = () => {
    dispatch(clearNrrdData());
  };

  const methods = {
    handleUploadFiles,
    handleRemoveUploadFile,
    handleConvertFiles,
    handleSetDownloadFile,
    handleSetNrrd,
    handleSetNrrdData,
    handleStopNrrdLoading,
    handleCancelConversion,
    handleClearDicomFiles,
    handleClearAllData,
  };

  return { dicomFiles, file, loading, methods };
};

export default useNrrdFile;
