import axios from 'axios';
import qs from 'qs';

import { API_HOST } from '../../constants';

export class Client {
  #axios;

  constructor() {
    this.#initInstance();
  }

  #initInstance = () => {
    this.#axios = axios.create({
      baseURL: API_HOST || window.location.origin,
      // timeout: API_REQUEST_TIMEOUT,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    });

    this.#axios.CancelToken = axios.CancelToken;
    this.#axios.isCancel = axios.isCancel;
    this.#axios.defaults.headers.common['Cache-Control'] = 'no-cache';
    this.#axios.defaults.headers.common.Pragma = 'no-cache';
    this.#axios.defaults.headers.common.Expires = '0';
  };

  async get(path, config) {
    const response = await this.#axios.get(path, config);

    return response;
  }

  async post(path, payload, config) {
    const response = await this.#axios.post(path, payload, config);

    return response;
  }

  async put(path, payload, config) {
    const response = await this.#axios.put(path, payload, config);

    return response;
  }

  async delete(path, config) {
    const response = await this.#axios.delete(path, config);

    return response;
  }

  setAuthToken(token) {
    this.#axios.defaults.headers.common.Authorization = token;
  }

  setWebsocketId(websocketId) {
    this.#axios.defaults.headers.common.WebsocketId = websocketId;
  }

  getCancelAndToken() {
    const { CancelToken } = this.#axios;
    const source = CancelToken.source();
    const { cancel, token } = source;

    return { cancel, token };
  }

  get instance() {
    return this.#axios;
  }

  addInterceptor(onResponseSuccess, onResponseFail) {
    this.#axios.interceptors.response.use(onResponseSuccess, onResponseFail);
  }
}

const client = new Client();

export default client;
