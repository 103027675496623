import { Login, NotFound, Model, Workflow, UserManagement, TargetInTooth, SystemConfig } from '../../pages';
import { APP_ROUTES } from '../../constants';

const APPLICATION_ROUTES = [
  {
    path: APP_ROUTES.LOGIN,
    page: Login,
  },
  {
    path: APP_ROUTES.NOT_FOUND,
    page: NotFound,
  },
  {
    path: APP_ROUTES.ROOT,
    page: Workflow,
    exact: true,
    auth: true,
  },
  {
    path: APP_ROUTES.FULL_JAW,
    page: Model,
    auth: true,
  },
  {
    path: APP_ROUTES.SINGLE_TOOTH,
    page: Model,
    auth: true,
  },
  {
    path: APP_ROUTES.TARGET_IN_TOOTH,
    page: TargetInTooth,
    auth: true,
  },
  {
    path: APP_ROUTES.USER_MANAGEMENT,
    page: UserManagement,
    auth: true,
  },
  {
    path: APP_ROUTES.SYSTEM_CONFIG,
    page: SystemConfig,
    auth: true,
  },
];

export default APPLICATION_ROUTES;
