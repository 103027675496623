import { createSlice } from '@reduxjs/toolkit';
import reducers, { initialState } from './modelReducer';
import extraReducers from './modelExtra';

const modelSlice = createSlice({
  name: 'model',
  initialState,
  reducers,
  extraReducers,
});

export default modelSlice;
