import userSlice from './userSlice';

export { getUsers } from './userExtra';

export const { setUserPagination, clearUserData, setUserSort } = userSlice.actions;

export * from './userActions';
export * from './userHook';

export default userSlice.reducer;
