import { useCallback } from 'react';
import { authService, configService, userService } from '../services';
import { getApiFunc } from '../utils/helpers/api';

const useAuth = () => {
  const login = useCallback(getApiFunc(authService.login), []);
  const relogin = useCallback(getApiFunc(authService.relogin), []);
  const logout = useCallback(getApiFunc(configService.logout), []);
  const changePassword = useCallback(getApiFunc(userService.changePasswordForCurrentUser), []);
  const selectWorkflow = useCallback(getApiFunc(configService.setCurrentWorkflow), []);

  return { login, relogin, logout, changePassword, selectWorkflow };
};

export default useAuth;
