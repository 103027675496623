import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification, Spin } from 'antd';
import client from '../../utils/client';
import { useAuth } from '../../hooks';
// import { authService } from '../../services';
import authInterceptor from './authInterceptor';
import { logout } from '../../store/rootActions';
import { removeLocalStorage } from '../../utils/helpers/storage';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [isCleanUp, setIsCleanUp] = useState({
    loading: true,
    process: false,
  });
  const dispatch = useDispatch();
  const authApi = useAuth();

  const setResponseUser = (responseUser) => {
    if (responseUser) {
      setUser(responseUser);
    } else {
      setUser(null);
    }
  };

  const onRelogin = async () => {
    const { data: responseUser } = await authApi.relogin(undefined, {
      onStart: () => setLoading(true),
      onFinished: () => setLoading(false),
    });

    if (responseUser) {
      setResponseUser(responseUser);
    }
  };

  const onLogin = async (authData) => {
    const { data: responseUser } = await authApi.login(authData, {
      onStart: () => setLoading(true),
      onFinished: () => setLoading(false),
      onError: (e) => {
        if (e.response) {
          notification.error({
            message: 'Login error',
            description: e.response?.data?.message,
          });
        } else {
          notification.error({
            message: 'Login error',
            description: 'Something was wrong',
          });
        }
      },
    });

    if (responseUser) {
      setResponseUser(responseUser);
    }
  };

  const onChangePassword = async (passwordData) => {
    await authApi.changePassword(passwordData, {
      onStart: () => setLoading(true),
      onFinished: () => setLoading(false),
      onSuccess: () => {
        notification.success({
          message: 'Password change',
          description: 'Password was changed successfully',
        });
      },
    });
  };

  const onLogout = async (writeLog) => {
    if (writeLog) {
      await authApi.logout(undefined, {
        onStart: () => setLoading(true),
        onFinished: () => setLoading(false),
        onSuccess: () => {
          setResponseUser(null);
          removeLocalStorage('token');
          removeLocalStorage('refresh_token');
          removeLocalStorage('workflow');
          // authService.logout();
          dispatch(logout());
        },
      });
    } else {
      setResponseUser(null);
      removeLocalStorage('token');
      removeLocalStorage('refresh_token');
      removeLocalStorage('workflow');
      // authService.logout();
      dispatch(logout());
    }
  };

  const initInterceptors = () => {
    client.addInterceptor(...authInterceptor({ onLogout }));
  };

  const addWebsocketId = (id) => {
    client.setWebsocketId(id);
  };

  useEffect(() => {
    initInterceptors();
    onRelogin();
  }, []);

  useEffect(() => {
    setIsCleanUp({
      loading: true,
      process: false,
    });
  }, [user]);

  if (loading) {
    return (
      <div style={{ width: '100%', height: '100vh' }}>
        <div className="spinner-center">
          <Spin size="large" />
        </div>
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        onLogin,
        onLogout,
        onChangePassword,
        addWebsocketId,
        isCleanUp,
        setIsCleanUp,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
