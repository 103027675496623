import { createAsyncThunk } from '@reduxjs/toolkit';
import { configService } from '../../services';
import { notification } from 'antd';

// extra actions
export const getConfigByParams = createAsyncThunk('systemConfig/getConfigByParams', async (_, thunkAPI) => {
  const { params } = thunkAPI.getState().systemConfig;
  const { data } = await configService.getConfigByParams(params);

  return { data };
});

export const updateConfigByParams = createAsyncThunk('systemConfig/updateConfigByParams', async ({ params }) => {
  await configService.updateConfigByParams(params);
});

export const getDataWithRequestParams = createAsyncThunk('systemConfig/getDataWithRequestParams', async ({ method }, thunkAPI) => {
  const { requestParams } = thunkAPI.getState().systemConfig;

  const { data } = await method(requestParams);

  return { data };
});

export default {
  [getConfigByParams.pending]: (state) => {
    state.loading = true;
  },
  [getConfigByParams.fulfilled]: (state, action) => {
    const { data } = action.payload;

    state.config = data.reduce((acc, val) => {
      acc[val.key] = val.value;

      return acc;
    }, {});
    state.loading = false;
  },
  [getConfigByParams.rejected]: (state) => {
    state.loading = false;
  },

  [updateConfigByParams.pending]: (state) => {
    state.loading = true;
  },
  [updateConfigByParams.fulfilled]: (state) => {
    state.loading = false;

    notification.success({
      message: 'System configuration',
      description: 'Configuration was saved successfully',
    });
  },
  [updateConfigByParams.rejected]: (state) => {
    state.loading = false;

    notification.error({
      message: 'System configuration',
      description: 'Something was wrong',
    });
  },

  [getDataWithRequestParams.pending]: (state) => {
    state.loading = true;
  },
  [getDataWithRequestParams.fulfilled]: (state, action) => {
    const { data } = action.payload;

    state.data = data;
    state.loading = false;
  },
  [getDataWithRequestParams.rejected]: (state) => {
    state.loading = false;
  },
};
