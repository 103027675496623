export const ROTATION_SPEED = 0;

export const JAW_SLICE_CAMERA_FACTOR = {
  sm: 2,
  md: 3,
  lg: 4,
};

export const TOOTH_SLICE_CAMERA_FACTOR = {
  sm: 6,
  md: 8,
  lg: 9,
};

export const JAW_MODEL_CAMERA_FACTOR = {
  sm: 3,
  md: 4,
  lg: 5,
};

export const TOOTH_MODEL_CAMERA_FACTOR = {
  sm: 10,
  md: 15,
  lg: 20,
};

export const SCREEN_SIZE = {
  md: 450,
  lg: 900,
};
