import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { ZIP, LOADING_CONSTANT } from '../../constants';
import { fileService } from '../../services';

// extra actions
export const saveSimodontModelToStore = createAsyncThunk('file/saveSimodontModelToStore', async (_, thunkAPI) => {
  const { file } = thunkAPI.getState().simodontModel;
  const { data } = await fileService.downloadFile({ id: file.id, ext: ZIP });

  return { data };
});

export const prepareSimodontModelAction = createAsyncThunk('file/prepareSimodontModelAction', async (_, thunkAPI) => {
  const { file } = thunkAPI.getState().nrrd;
  const { backgroundColor } = thunkAPI.getState().model;
  const { info, teethInfo } = thunkAPI.getState().simodontModel;

  try {
    await fileService.prepareSimodontModel({
      id: file.id,
      fromNrrd: true,
      backgroundColor,
      teethInfo: teethInfo.map(({ toothId, toothPosition }) => ({ toothId, toothPosition })),
      ...info,
    });
  } catch (e) {
    throw thunkAPI.rejectWithValue(e.response);
  }
});

export const uploadTargetInToothDataAction = createAsyncThunk(
  'file/uploadTargetInToothDataAction',
  async (_, thunkAPI) => {
    const { formData, info: { name, description, toothId } } = thunkAPI.getState().simodontModel;
    const formDataToSend = new FormData();

    Object.keys(formData).forEach((key) => {
      if (formData[key]) {
        formDataToSend.append(key, formData[key]);
      }
    });
    formDataToSend.append('name', name);
    formDataToSend.append('description', description);
    formDataToSend.append('toothId', toothId);

    await fileService.prepareTargetInToothModel({ formData: formDataToSend });
  },
);

export const cancelSimodontModelConversion = createAsyncThunk('file/cancelSimodontModelConversion', async (_, thunkAPI) => {
  const { file } = thunkAPI.getState().nrrd;

  await fileService.cancelSimodontModelConversion({ id: file.id });
});


// extra reducer
export default {
  [saveSimodontModelToStore.pending]: (state) => {
    state.loading[LOADING_CONSTANT.SIMODONT_MODEL_CONVERSION] = false;
    state.loading[LOADING_CONSTANT.SIMODONT_MODEL_DOWNLOAD] = true;

    notification.success({
      key: LOADING_CONSTANT.SIMODONT_MODEL_CONVERSION,
      duration: 3,
      message: 'Simodont model conversion (100%)',
      description: 'Files have been converted! Result is uploading',
    });
  },
  [saveSimodontModelToStore.fulfilled]: (state, action) => {
    const { data } = action.payload;

    state.file = { ...state.file, data };
    state.loading[LOADING_CONSTANT.SIMODONT_MODEL_DOWNLOAD] = false;
  },
  [saveSimodontModelToStore.rejected]: (state) => {
    state.loading[LOADING_CONSTANT.SIMODONT_MODEL_DOWNLOAD] = false;

    notification.error({
      key: LOADING_CONSTANT.SIMODONT_MODEL_CONVERSION,
      duration: 3,
      message: 'Simodont model downloading',
      description: 'Error when loading result',
    });
  },

  [prepareSimodontModelAction.pending]: (state) => {
    state.loading[LOADING_CONSTANT.SIMODONT_MODEL_CONVERSION] = true;
    state.file = { id: 0, data: null };

    notification.success({
      key: LOADING_CONSTANT.SIMODONT_MODEL_CONVERSION,
      duration: 0,
      message: 'Simodont model conversion (0%)',
      description: 'Simodont model is preparing! Please wait for the end',
    });
  },
  [prepareSimodontModelAction.fulfilled]: (state) => {
    state.loading[LOADING_CONSTANT.SIMODONT_MODEL_CONVERSION] = true;
  },
  [prepareSimodontModelAction.rejected]: (state, action) => {
    state.loading[LOADING_CONSTANT.SIMODONT_MODEL_CONVERSION] = false;

    if (action.payload?.status === 400) {
      notification.error({
        key: LOADING_CONSTANT.SIMODONT_MODEL_CONVERSION,
        duration: 3,
        message: 'Simodont model conversion',
        description: action.payload.data.message
          .replaceAll('{', '')
          .replaceAll('}', '')
          .split('=')[1],
      });
    } else {
      notification.error({
        key: LOADING_CONSTANT.SIMODONT_MODEL_CONVERSION,
        duration: 3,
        message: 'Simodont model conversion',
        description: 'Error when preparing simodont model',
      });
    }
  },

  [uploadTargetInToothDataAction.pending]: (state) => {
    state.loading[LOADING_CONSTANT.SIMODONT_MODEL_CONVERSION] = true;
    state.file = { id: 0, data: null };

    notification.success({
      key: LOADING_CONSTANT.SIMODONT_MODEL_CONVERSION,
      duration: 0,
      message: 'Simodont model conversion (0%)',
      description: 'Simodont model is preparing! Please wait for the end',
    });
  },
  [uploadTargetInToothDataAction.fulfilled]: (state) => {
    state.loading[LOADING_CONSTANT.SIMODONT_MODEL_CONVERSION] = true;
  },
  [uploadTargetInToothDataAction.rejected]: (state) => {
    state.loading[LOADING_CONSTANT.SIMODONT_MODEL_CONVERSION] = false;

    notification.error({
      key: LOADING_CONSTANT.SIMODONT_MODEL_CONVERSION,
      duration: 3,
      message: 'Simodont model conversion',
      description: 'Error when preparing simodont model',
    });
  },
};
