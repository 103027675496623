import { getConfigByParams, getDataWithRequestParams, setParams, setRequestParams, updateConfigByParams } from '.';

export const setParamsAndFetchConfig = (params) => (dispatch) => {
  dispatch(setParams(params));
  dispatch(getConfigByParams());
};

export const updateParamsAndFetchConfig = (params) => async (dispatch) => {
  await dispatch(updateConfigByParams({ params }));
  await dispatch(getConfigByParams());
};

export const setRequestParamsAndFetch = (params, method) => async (dispatch) => {
  await dispatch(setRequestParams(params));
  await dispatch(getDataWithRequestParams({ method }));
};

