import { saveModelToStore, setIdModel, setIdOBJ, saveOBJToStore } from '.';

export const setModelIdAndDownload = (body) => (dispatch) => {
  dispatch(setIdModel(body));
  dispatch(saveModelToStore(body));
};

export const setOBJIdAndDownload = (body) => (dispatch) => {
  dispatch(setIdOBJ(body));
  dispatch(saveOBJToStore(body));
};
