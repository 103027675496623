import {
  saveNrrdToStore,
  setIdNrrd,
  clearSimodontModelData,
  clearModelData,
  uploadDicomFilesAction,
  prepareModelAction,
  uploadNrrdDataAction,
} from '../index';

export const uploadDicomAndClearOtherData = () => (dispatch) => {
  dispatch(clearSimodontModelData());
  dispatch(clearModelData());
  dispatch(uploadDicomFilesAction());
};

export const setIdNrrdAndDownload = (body) => (dispatch) => {
  dispatch(setIdNrrd(body));
  dispatch(saveNrrdToStore(body));
};

export const setIdNrrdAndPrepareModel = (body) => (dispatch) => {
  dispatch(setIdNrrd(body));
  setTimeout(() => {
    dispatch(prepareModelAction());
  }, 3000);
  dispatch(saveNrrdToStore(body));
};

export const uploadNrrdAndClearOtherData = () => (dispatch) => {
  dispatch(clearSimodontModelData());
  dispatch(clearModelData());
  dispatch(uploadNrrdDataAction());
};
